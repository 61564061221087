
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { apiOperationRuleGetDetail,
         apiOperationRuleDetailUpdate,
         apiOperationRuleCreate } from '../services/tpsApi/tpsApiOperationRule';
import { IOperationRuleDetail } from '../services/tpsApi/models/operationRule';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
//import DatepickerLite from 'vue3-datepicker-lite';
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
    //DatepickerLite,
    WarningModal
  },
  emits: ["hide-form"]
})

export default class OperatingRulesForm extends Vue {
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;
    TICKET_CORP_CHECKBOX = ["悠遊卡","一卡通","愛金卡"];
    WRITE: number | undefined = undefined;
     originalData: IOperationRuleDetail = {} as any;
    formData: IOperationRuleDetail = {} as any;
    actionType: string = "";
    ruleID: number = -1;

    //Date picker setting
    // datepickerSetting = {
    //     id: "datepicker",
    //     class: "md-input myDateInput md-datepicker",
    //     locale: {
    //       format: "YYYY-MM-DD",
    //       weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    //       todayBtn: "Today",
    //       clearBtn: "Clear",
    //       closeBtn: "Close",
    //     },
    //     changeEvent: (value) => {
    //       this.formData["publish_time"] = value;
    //     }
    // }

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }
    async initForm(pActionType, pRuleID) {
        this.actionType = pActionType;
        this.ruleID = pRuleID;
        if(pRuleID) {
            this.formData = Object.assign({}, await apiOperationRuleGetDetail({id: pRuleID}));
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            //Set default date
            // let today = new Date();
            // let dd = String(today.getDate()).padStart(2, '0');
            // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            // let yyyy = String(today.getFullYear());
            // this.formData["publish_time"] = yyyy + "-" + mm + '-' + dd;
            // this.formData["filename"] = String(Date.now());
        }    
    }
    
    async updateDetail(pSave: boolean) {
        if(pSave) {
            let data = {} as any;
            if(this.formData["json"]) {
                try {
                    data["json"] = JSON.parse(this.formData["json"]);
                }
                catch {
                    alert("json欄位錯誤：請輸入正確JSON格式");
                    return;
                }
            }
            let dt = new Date();
            this.formData["modified_time"] = `${
                (dt.getMonth()+1).toString().padStart(2, '0')}-${
                dt.getDate().toString().padStart(2, '0')}-${
                dt.getFullYear().toString().padStart(4, '0')} ${
                dt.getHours().toString().padStart(2, '0')}:${
                dt.getMinutes().toString().padStart(2, '0')}:${
                dt.getSeconds().toString().padStart(2, '0')}`;
            this.formData["modified_user"] = this.$store.getters.currentUser;
            if(this.actionType == "update") {
                let res = await apiOperationRuleDetailUpdate({id: this.ruleID, data: arrangeUpdateData(this.formData,this.originalData)});
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }      
            else if(this.actionType == "create") {
                let res = await apiOperationRuleCreate(this.formData);
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }    
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }

    handleCancel() {
        this.hideForm(false);
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

}
